.learning-goal {
  &:hover {
    background-color: $cockpit-content-navigation-bg-color;
  }
}

.learning-goal__lessons {
  width: 242px;
}

.learning-goal__goals_options {
  label {
    margin-left: 2rem;
    font-weight: bold;
  }

  input {
    margin-right: 0.5rem;
  }
}
