.asset--table {
  & > .caption { margin-top: 0.5rem; }

  @include tablet-landscape() {
    max-width: none;
  }

  &.asset--wide {
    margin-left: 0 !important;
    margin-right: 0 !important;
    display: block;
    overflow-x: auto;
    width: 100%;
    .asset__table { width: 100%; }
  }
}

.asset__table {
  width: auto;
  background-color: transparent;
  border-collapse: collapse;
}

.asset__table td,
.asset__table th {
  padding: 0.5em;
  color: currentColor;
  text-align: left;

  &.text-align-right {
    text-align: right;
  }

  &.text-align-center {
    text-align: center;
  }

  &.text-align-justify {
    text-align: justify;
  }

  & > *:last-child { margin-bottom: 0; }
  & > *:last-child > *:last-child { margin-bottom: 0; }
}

.asset__table,
.asset__table td,
.asset__table th {
  background-color: $white;
}

.asset__table th { font-weight: $weight-bold; }

.section--case,
.section--example,
.section--interaction-example {
  .asset__table,
  .asset__table td,
  .asset__table th {
    color: $body-color;
  }

  .asset__table thead th {
    color: $white;
  }
}

.asset__table thead th {
  background-color: $tm-neutral-heavy;
  color: $white;
}

//
// Borders
//
.asset__table {
  border: 1px solid $tm-neutral-heavy;
}
.asset__table-cell {
  border-right: 1px solid $tm-neutral-heavy;
  border-bottom: 1px solid $tm-neutral-heavy;

  .asset--wide & {
    white-space: nowrap;
    p {
      display: inline-block;
    }
  }
}

.asset__table thead th.asset__table-cell {
  border-right-color: $white;
  border-left-color: $white;
  border-bottom: 0 none;
  border-top: 1px solid $white;
  &:first-child {
    border-left-color: $tm-neutral-heavy;
  }

  &:last-child {
    border-right-color: $tm-neutral-heavy;
  }
}

.asset__table thead tr:first-child th.asset__table-cell {
  border-top: 0 none;
}

.asset__table--no-border {
  border-width: 0;
}
.asset__table,
.asset__table--no-border {
  .asset__table-cell--no-row-border {
    border-bottom-width: 0;
  }
  .asset__table-cell--no-col-border {
    border-right-width: 0;
  }

  .asset__table-cell--with-currency {
    white-space: nowrap;

    & > div > p {
      flex: 1;
      text-align: right;
    }
  }

  .asset__table-cell--linetype-subtotal {
    border-top: 1px $tm-neutral-extrablack solid;
  }

  .asset__table-cell--total {
    &:before, p:first-child {
      border-top: 1px $tm-neutral-extrablack solid;
    }
  }

  .asset__table-cell--total {
    position: relative;
    padding: 0 0.25rem;
    span.total {
      display: block;
      width: 100%;
      padding: 0.5rem 0.25rem 0.5rem 1.25rem;
      &:after {
        content: "";
        position: absolute;
        width: calc(100% - 0.5rem);
        height: 4px;
        border-top: 1px solid $tm-neutral-extrablack;
        border-bottom: 1px solid $tm-neutral-extrablack;
        left: 0.25rem;
        bottom: -1px;
      }
    }
  }
}

.text-fade{
  color:#aaa;
}

.disabled--tr td {
  position: relative;
  opacity: 0.5;
  pointer-events: none;
  &:not(.no-disable-color) {
    &:after {
      content: '';
      position: absolute;
      background-color: #000;
      opacity: 0.1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.archived--tr td:not(.student-name) {
  color: rgba($base-text-color, 0.4);
}
