.card {
  filter: drop-shadow(0 3px 5px rgba(0, 0, 0, 0.12));
  position: relative;

  .card__lesson-status-label {
    align-items: center;
    border-bottom-left-radius: 5px;
    bottom: 0;
    display: flex;
    height: 30px;
    justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: all .3s ease-in-out;
    width: 30px;
    z-index: 9;

    &::before {
      border-bottom: 24px solid $white;
      border-left: 24px solid $white;
      border-right: 24px solid transparent;
      border-top: 24px solid transparent;
      filter: drop-shadow(0px 0px 3px rgba(0,0,0,0.25));
      bottom: 0;
      content: '';
      display: flex;
      left: 0;
      position: absolute;
    }
  }
}

.card--bordered {
  border: 2px solid transparent;
  transition: border-color .5s ease-in-out;

  &.card--added-to-activities {
    border: 2px solid #97BE0C;
  }
}

.card--modal-trigger {
  cursor: pointer;
}

.card--completed,
.card--lesson-completed {
  .card__lesson-status-label {
    opacity: 1;

    > svg {
      z-index: 10;
    }
  }
}
