.lesson__category {
  max-height: 2000px;
  transition: all 0.25s;
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }

  .category__header {
    margin-bottom: 1.5rem;
    display: flex;
  }

  .category__header-stopwatch {
    margin-top: 0.25rem;
    margin-right: 0.5rem;
  }

  .category__header-text {
    flex-grow: 1;
  }

  .category__title {
    margin-bottom: 0;
  }

  .category__link {
    color: $body-color;
    font-size: 24px;
    line-height: 1.5;
  }

  .category__subtitle {
    color: $lesson-category-subtitle-color;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &--empty {
    max-height: 0;
    overflow: hidden;
    margin-bottom: 0 !important;
  }
}
