.question-index {
  margin: 0;
  position: relative;
}

.question-index__item {
  margin-bottom: 0.3125rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.question-index__link {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: $body-color;
  text-align: right;
  background: $tm-background-gray;
  padding: 0.5rem 0.625rem;
  font-size: 0.875rem;
  line-height: 1.25;
  min-height: 2.6875rem;
}

.question-index__label {
  padding-left: 1.15rem; // space for checkmark
  @include tablet-only() {
    display: none;
  }
}

.question-index__label--no-number {
  @include tablet-only() {
    display: block;
  }
  padding-left: 0;
}

.question-index__link--done {
  background-color: $white;
  @include icon-before($icon: $pika-check, $margin-right: .25em);

  &:before {
    position: absolute;
    left: 0.625rem;
    font-size: 1rem;
    vertical-align: baseline;
    color: $tm-neutral-extraheavy;
  }
}

.question-index__link--active {
  .question-index__label {
    font-weight: $weight-bold;
    color: $white;
  }

  @include tablet() {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
  }

  @include desktop() {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
    .question-index__label {
      font-size: 1rem;
    }
  }

  &.question-index__link--done:before {
    // Change color and position of checkmark icon
    color: $white;
    left: 0.625 + 0.25rem;
  }
}

.question-index__link--disabled {
  cursor: default;
  pointer-events: none;

  .question-index__label,
  .question-label,
  &:before {
    opacity: 0.4;
  }
}

.question-index--simplified {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .question-index__link {
    display: inline-block;
    font-size: 1.25rem;
    background-color: $white;
    font-weight: $weight-bold;
    text-align: center;
    line-height: 1.875rem;
    border-radius: 6px;
    padding: 0 0.5rem;
    margin-right: 0.625rem;
    color: $tm-neutral-black;

    &:last-child {
      margin-right: 0;
    }

    @include empty-placeholder();

    @each $category, $colors in $score-categories {
      &--score-#{$category} {
        background-color: map-get($colors, background-color);
        color: map-get($colors, color);
      }
    }
  }

  .question-index__link--done:before {
    display: none;
  }

  .question-index__link--answered {
    background-color: $black;
    color: $white;
  }

  .question-index__link--score-poor {
    color: $white;
  }

  .question-index__link--active {
    background-color: $tm-blue-dark;
    color: $white;
    margin-left: 0;
    margin-right: 0.625rem;
    min-width: 2.5rem;
    line-height: 2.5rem;

    &:last-child {
      margin-right: 0;
    }

    &.question-index__link--score-none {
      background-color: $tm-blue-dark;
      color: $white;
    }
  }
}
