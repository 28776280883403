.summon-lists__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid $cockpit-header-border-color;
  padding-bottom: 1rem;
}

.summon-lists__button { margin: 0.1rem 0; }

.summon-list-prompt {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.summon-list-prompt__container {
  background: $white;
  position: relative;

  @include tablet() { min-width: 800px; }

  box-shadow: 0 0 40px rgba($black, 0.075);
}

.summon-list-prompt__body {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.summon-list-prompt__secondary-action {
  border-top: 1px solid $tm-neutral-light;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.summon-list-prompt__logo {
  height: 3rem;
  width: auto;
}

.summon-list-prompt__header {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.summon-list-prompt__icon {
  font-size: 3rem;
}

.summon-list-prompt__flash {
  padding: 2rem 2rem 0 2rem;
  //border-bottom: 1px solid $tm-neutral-light;
  display: flex;
  justify-content: center;
  text-align: center;
}

.navbar-item--summon-list-widget {
  background-color: $white !important;
  &:active:after { display: none; }
}

.summon-list-prompt__button-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  form:first-child {
    margin-bottom: 1rem;
  }
}
