.cockpit-header {
  background-color: $cockpit-header-bg-color;
}

.cockpit-header__inner {
  display: flex;
  justify-content: space-between;

  @include mobile() {
    flex-direction: column;
  }
}

.cockpit-header__logo-wrapper {
  align-items: center;
  display: flex;
}

.cockpit-header__logo-container {
  margin: 0.5rem 1rem;
}

.cockpit-header__logo {
  height: 64px;
  min-width: 128px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
  background-position-x: left;
}

.cockpit-header__logo--dropdown {
  margin: 0.5rem auto;
  height: 64px;
  width: 128px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
  background-position-x: center;
}

.cockpit-header__guided-tour-button {
  margin-right: 2rem;
  color: $base-text-color;
  &:hover {
    color: $cockpit-light-blue;
  }
  .icon-help {
    font-size: 1.25rem;
  }
}

.cockpit-header__navigation {
  align-items: center;
  display: flex;

  @include mobile() {
    border-bottom: 1px solid $cockpit-header-border-color;
    justify-content: space-between;
    padding-bottom: 1rem;
    padding-top: 1.5rem;
  }
}

.cockpit-header__stream-selector {
  .dropdown--minimalistic:not(:first-child) {
    @media only screen and (max-width: 767px) {
      margin-top: .5rem;
    }
    @media only screen and (min-width: 768px) {
      margin-left: 2rem;
    }
  }
}

.cockpit-header__stream-selector {
  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;

    .dropdown {
      display: flex;
    }
  }
}

.cockpit-header__feedback-bubble {
  align-items: center;
  background-color: #fff;
  border: 1px solid $cockpit-header-feedback-bubble-color;
  border-radius: .25rem;
  color: $cockpit-header-feedback-bubble-color;
  display: flex;
  line-height: 1rem;
  margin-left: 1.5rem;
  padding: .5rem 1rem .5rem .5rem;
  position: relative;
  transition: color .3s, border .3s;

  &:hover {
    border-color: $cockpit-header-feedback-bubble-color-hover;
    color: $cockpit-header-feedback-bubble-color-hover;
  }

  &::before,
  &::after {
    border-style: solid;
    bottom: auto;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    width: 0;
  }

  &::before {
    border-color: transparent $cockpit-header-feedback-bubble-color;
    border-width: 8px 8px 8px 0;
    left: -8px;
    margin-top: -8px;
    transition: border .3s;
  }

  &::after {
    border-color: transparent #fff;
    border-width: 7px 6px 7px 0;
    left: -6px;
    margin-top: -7px;
  }

  .icon {
    height: 1rem;
    margin-right: .5rem;
    margin-top: .125rem;
    width: 1rem;
  }
}

.test-goal-header {
  background-color: $cockpit-light-grey;
  height: 3rem;
  align-items: center;
  width: 100%;
}
