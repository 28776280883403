.lesson-content {
  justify-content: space-between;
  &__details-print-button {
    align-items: center;
    color: $grey-lighter;
    display: flex;
    transition: color .3s ease-in-out;

    .icon-printer {
      font-size: 1rem;
      margin-left: .325rem;
      margin-top: .25rem;
    }

    &:hover {
      color: $grey-light;
    }
  }
}

.lesson-content__body {
  strong {
    display: inline-block;
    margin-right: .25rem;
  }

  > p, > ul, > ol {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;

    &:not(:last-child) {
      border-bottom: 1px solid $cockpit-content-border-bottom-color;
      margin-bottom: 2rem;
      padding-bottom: 1rem;
    }
  }

  .list {

    > li {
      margin-left: 1.125rem;
      padding-left: 0;
    }
  }

  .section .h4 {
    color: $cockpit-blue;
    font-size: .875rem;
    text-transform: uppercase;
  }
}

