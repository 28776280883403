.progress-bar {
  align-items: center;
  display: flex;
}

.progress-bar__tube {
  background-color: $white;
  border: 1px solid #009cbc;
  height: 2rem;
  padding: 2px;
  position: relative;
  width: 15rem;
}

.progress-bar__sand {
  background-color: #009cbc;
  display: block;
  height: 100%;
  width: 100%;
  transition: all .3s linear;
}

.progress-bar__timer {
  display: flex;
  justify-content: center;
  margin-left: .5rem;
  width: 4rem;
}
