$button-icon-only-size: baseline(1.5);
$button-active-background-color: $tm-blue;
$button-teacher-color: #FF5353;

@mixin button-color(
  $background-color,
  $hover-background-color: $background-color,
  $text-color: $body-color,
  $hover-text-color: $text-color
) {
  color: $text-color;
  background-color: $background-color;
  border: 1px solid $background-color;

  &:not(:disabled):not(.button--disabled):hover,
  &:not(:disabled):not(.button--disabled):active {
    color: $hover-text-color;
    background-color: $hover-background-color;
    border: 1px solid $hover-background-color;
  }
  &.button--inverse {
    color: $background-color;
    background-color: $text-color;

    @if $text-color == $white {
      border: 1px solid $background-color;
    } @else {
      border: 1px solid $text-color;
    }

    &:not(:disabled):not(.button--disabled):hover,
    &:not(:disabled):not(.button--disabled):active {
      color: $hover-background-color;
      background-color: $hover-text-color;
    }
  }
}

.button {
  font-family: $button-font;
  font-weight: $button-font-weight;
  text-rendering: optimizeLegibility;
  color: $body-color;
  display: inline-block;
  cursor: pointer;
  line-height: 1em;
  font-size: $button-font-size;
  border-radius: $button-border-radius;
  border: none;
  white-space: nowrap;
  padding: $button-padding;
  transition: background-color 300ms;
  outline: none;

  &:not(:disabled):not(.button--disabled):hover,
  &:not(:disabled):not(.button--disabled):active {
    color: currentColor;
  }

  & > [class^="icon-"], > [class*=" icon-"] {
    font-size: $button-icon-size;
    line-height: $button-icon-line-height;
    margin-top: $button-icon-margin-top;
  }
  & > [class^="icon-tm-"], > [class*=" icon-tm-"] {
    font-size: 1em;
    margin-top: 0;

    &.icon-tm-chevron-left,
    &.icon-tm-chevron-right {
      line-height: 0;
      font-size: 1.5em;
    }
  }

  // only used in simplified
  &.button--submit {
    border-radius: 0.5rem;
    font-size: 2rem;
    width: 4rem;
    padding: 0.28rem 0;
    text-align: center;

    transition: all .3s ease-in-out;

    & > [class^="icon-"], > [class*=" icon-"] {
      margin-top: -0.1em;
    }

    &, &:disabled, &:not(:disabled):hover, &:hover {
      color: $white;
    }

    &:disabled {
      background-color: $disabled-button-color;
    }

    &:not(:disabled) {
      background-color: $button-active-background-color;
      &:hover {
        background-color: darken($button-active-background-color, 10);
      }
    }
  }
}

.button--sans-serif {
  font-family: $family-sans-serif;
}

.button--primary {
  @include button-color(
    $background-color: $primary-button-color,
    $hover-background-color: darken($primary-button-color, 7.5%),
    $text-color: $white
  );
  &.exercise-check {
    &:not(:disabled):not(.button--disabled):focus {
      color: $primary-button-color;
      background-color: $white;
      -webkit-box-shadow:inset 0 0 0 2px $primary-button-color;
      -moz-box-shadow:inset 0 0 0 2px $primary-button-color;
      box-shadow:inset 0 0 0 2px $primary-button-color;
    }
  }
}

.button--secondary {
  @include button-color(
    $background-color: $secondary-button-color,
    $hover-text-color: $black
  );
  border: $secondary-button-border;
  padding: $secondary-button-padding;
  &:not(:disabled):not(.button--disabled):hover,
  &:not(:disabled):not(.button--disabled):active {
    border: $secondary-button-border-hover;
  }
}

.button--print {
  @include button-color(
    $background-color: transparent,
    $hover-text-color: $black
  );
}

.button--light {
  @include button-color(
    $background-color: $light-button-color,
    $hover-text-color: $black
  );
}

.button--small {
  font-size: 1rem;
  & > [class^="icon-"], > [class*=" icon-"] {
    font-size: 1em;
  }
}

.button--tiny {
  font-size: 0.875rem;
  font-family: $family-sans-serif;
}

.button--large { font-size: 1.4rem; }
.button--larger { font-size: 2rem; }

.button--retry {
  & > [class^="icon-"]:before,
  & > [class*=" icon-"]:before {
    transition: transform 200ms ease-in-out;
    display: inline-block;
  }

  &:hover > [class^="icon-"]:before,
  &:hover > [class*=" icon-"]:before {
    transform: rotate(30deg);
  }

  &:active > [class^="icon-"]:before,
  &:active > [class*=" icon-"]:before,
  &.is-loading > [class^="icon-"]:before,
  &.is-loading > [class^=" icon-"]:before {
    transform: rotate(60deg);
  }
}

.button--unlock {
  &:hover > [class^="icon-"]:before,
  &:hover > [class*=" icon-"]:before,
  &:active > [class^="icon-"]:before,
  &:active > [class^=" icon-"]:before,
  &.is-loading > [class^="icon-"]:before,
  &.is-loading > [class^=" icon-"]:before {
    content: $pika-unlock !important;
  }
}

.button--dark {
  @include button-color(
    $background-color: $grey-darker,
    $text-color: $white,
    $hover-background-color: $grey-dark
  );

  border: none;
}

.button--icon-only {
  width: $button-icon-only-size;
  height: $button-icon-only-size;
  display: inline-block;
  overflow: hidden;
  padding: 0;
  text-align: center;

  > [class^="icon-"], > [class*=" icon-"] {
    line-height: $button-icon-only-size;
    &:before { line-height: inherit; }
    &.icon-tm-arrow-left { margin-left: 0.25rem; font-size: 1.6em; }
    &.icon-tm-arrow-right { margin-left: -0.25rem; font-size: 1.6em; }
  }
}

.button--hoverable-icon-left {
  @include with-hoverable-icon-left();
}
.button--hoverable-icon-right {
  @include with-hoverable-icon-right();
}

.button.is-loading {
  pointer-events: none;
  cursor: default;
  position: relative;
  overflow: hidden;
  vertical-align: top;

  &.button--primary {
    background-color: tint($primary, 75%);
  }

  &.button--light {
    color: $tm-neutral-dark;
    background-color: $light-button-color;
  }

  &.button--submit {
    background-color: tint($button-active-background-color, 75%);
  }
}

.button.is-loading:not(.button--circle) {
  @include loading-bar($height: 3px);

  &.button--primary:before {
    background-color: $primary;
  }

  &.button--light:before {
    background-color: $body-color;
  }

  &.button--submit:before {
    background-color: $button-active-background-color;
  }

  &.button--cockpit:before {
    background-color: $white;
  }
}

.button--circle.is-loading {
  @include loading-circle($height: 3px);

  &.button--primary:before {
    border-left-color: $primary;
  }

  &.button--secondary:before {
    border-left-color: $secondary-button-color;
  }

  &.button--light:before {
    border-left-color: $body-color;
  }
}

// Legacy fix for check buttons created with `form_widget`
.button.icon-check:before {
  content: "}";
  margin-right: 0.75rem;
  line-height: 1;
  font-size: 0.8em;
}

.link-button {
  border: 0;
  background: transparent;
  font-size: 1.1rem;
  display: inline-block;
  line-height: 1.5;
  cursor: pointer;
  white-space: nowrap;
  outline: none;
  color: $body-color;
  padding: 0.33em 0.75em;

  &:hover,
  &:active {
    color: $black;
  }

  & > [class^="icon-"], > [class*=" icon-"] {
    vertical-align: middle;
    line-height: 1;
    font-size: 0.8em;
  }

  &--danger {
    color: $warning-color;

    &:hover,
    &:active {
      color: darken($warning-color, 15%);
    }
  }

  &--icon-only {
    line-height: 100%;
    & > [class^="icon-"], > [class*=" icon-"] {
      font-size: 1em;
    }
  }

  &--disabled,
  &:disabled {
    pointer-events: none;
    cursor: default;
    color: $disabled-button-text-color;
  }
}

.button--block {
  display: block;
  width: 100%;
  text-align: center;
}

.button--circle {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  font-size: 1.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button--circle.button--large {
  width: 6rem;
  height: 6rem;
  font-size: 3rem;
}

.button--scale-up { transform: scale(1.1); }

.close-button {
  border: none;
  outline: none;
  @include close-button(40px, 1px, $tm-neutral-extrablack, $tm-neutral-extrablack, 60%);

  &:hover,
  &:focus,
  &:active {
    background-color: $tm-neutral-pale;
    cursor: pointer;
  }

  &--primary {
    background-color: $primary;
    @include close-button(2.5rem, 2px, $white, $white, 60%);
    &:hover,
    &:focus,
    &:active {
      background-color: darken($primary-button-color, 7.5%);
    }
  }

  &--dark {
    background-color: rgba($black, 0.25);
    @include close-button(40px, 1px, $white, $white, 60%);
    &:hover,
    &:focus,
    &:active {
      background-color: rgba($black, 0.5);
    }
  }

  &--disabled,
  &:disabled {
    opacity: 0.2;
    pointer-events: none;
    cursor: default;
  }

  &--modal, &--modal-navbar {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
  &--modal {
    @include force-size(48px, 48px);
    @include desktop() { @include force-size(64px, 64px); }
  }
  &--modal-navbar {
    @include force-size(52px, 52px);
  }
}

$cockpit-blue: #0190d4;

.button--cockpit {
  font-family: $family-sans-serif;
  font-weight: $weight-normal;

  &.button--primary {
    @include button-color(
      $background-color: $cockpit-blue,
      $text-color: $white,
      $hover-background-color: darken($cockpit-blue, 7.5%)
    );
  }
  &.button--disabled,
  &.button:disabled {
    pointer-events: none;
    cursor: default;
    color: $disabled-button-text-color;
    background-color: $disabled-button-color;
    border-color: $disabled-button-color;
  }
}
.button--cockpit-with-icon-only {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 2rem;
}

.button--disabled,
.button:disabled {
  pointer-events: none;
  cursor: default;
  color: $disabled-button-text-color;
  background-color: $disabled-button-color;
}

.button-group {
  display: inline-flex;
  vertical-align: middle;
}

.button-group--loading {
  pointer-events: none;
  cursor: default;

  .button-group__button { opacity: 0.5; }
}

.button-group__button {
  border-radius: 0;
  transition: none;

  &.button { margin-left: 0; }

  &:first-child {
    border-top-left-radius: $button-border-radius;
    border-bottom-left-radius: $button-border-radius;

    &:not(:last-child) {
      border-right: none;
    }
  }

  &:last-child {
    border-top-right-radius: $button-border-radius;
    border-bottom-right-radius: $button-border-radius;

    &:not(:first-child) {
      border-left: none;
    }
  }

  &--outline {
    background: $white;
    border: 1px solid $tm-neutral;
  }

  &--selected {
    color: $white;
    background: $link-color;
    border-color: $link-color;

    &:hover,
    &:active {
      color: $white !important;
      background: $link-color;
      border-color: $link-color;
    }

    .button-group--cockpit & {
      color: $white;
      background: $cockpit-blue;
      border-color: $cockpit-blue;

      &:hover,
      &:active {
        color: $white !important;
        background: $cockpit-blue;
        border-color: $cockpit-blue;
      }
    }
  }

  &--teacher-selected {
    color: $white;
    background: $button-teacher-color;
    border-color: $button-teacher-color;

    &:hover,
    &:active {
      color: $white !important;
      background: $button-teacher-color;
      border-color: $button-teacher-color;
    }
  }

  &--teacher-deselected {
    color: $button-teacher-color;
    background: $white;
    border-color: $button-teacher-color;

    &:hover,
    &:active {
      color: $button-teacher-color !important;
      background: $white;
      border-color: $button-teacher-color;
    }
  }

  &--disabled, &:disabled {
    background-color: inherit;
  }
}

.button-group__button--selected:disabled,
.button-group__button--selected.button-group__button--disabled {
  background: rgba($link-color, .3);
  border-color: rgba($link-color, .3);
  .button-group--cockpit & {
    background: rgba($cockpit-blue, .3);
    border-color: rgba($cockpit-blue, .3);
  }
}

.button-group__button--teacher-selected:disabled,
.button-group__button--teacher-selected.button-group__button--disabled {
  background: rgba($button-teacher-color, .3);
  border-color: rgba($button-teacher-color, .3);
}

.button-container,
.button-with-message-container {
  display: inline-flex;
  flex-direction: row;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}
