.share-menu {
  &__buttons {
    position: relative;
    display: flex;
    margin-bottom: 0.2rem;
  }
  &__title {
    margin-bottom: 0.5rem;
    white-space: nowrap;
  }
  &__button {
    display: inline-block;
    height: 32px;
    margin-right: 0.5rem;
    a {
      height: 32px;
      img {
        width: 32px;
        height: 32px;
      }
    }
  }
  &__button-icon {
    display: inline-block;
    width: 32px;
    height: 32px;
  }
}
