$item-margin: 1.25rem;

.cards-container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.card {
  background-color: $white;
  cursor: pointer;
  display: flex;
  margin-bottom: $item-margin;

  &--landscape {
    min-height: 220px;

    @media only screen and (max-width: 700px) {
      flex: 1 1 100%;
    }

    @media only screen and (min-width: 701px) and (max-width: ($fullhd - 1px)) {
      flex: 0 1 calc(50% - #{divide($item-margin, 2)});

      &:not(:nth-child(2n)) {
        margin-right: $item-margin;
      }
    }

    @media only screen and (min-width: $fullhd) {
      flex: 0 1 calc(32.99% - #{divide($item-margin, 2)});

      &:not(:nth-child(3n)) {
        margin-right: $item-margin;
      }
    }
  }

  &--portrait {
    height: 260px;

    @media only screen and (max-width: 700px) {
      flex: 1 1 100%;
    }

    @media only screen and (min-width: 701px) and (max-width: ($fullhd - 1px)) {
      flex: 0 0 calc(33% - #{divide($item-margin, 2)});

      margin-right: $item-margin;
    }

    @media only screen and (min-width: $fullhd) {
      flex: 0 1 calc(16.66% - #{divide($item-margin, 2)});
      max-width: 200px;

      margin-right: $item-margin;
    }
  }
}
