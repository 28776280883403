.table--cockpit {
  td, th {
    vertical-align: baseline;
    padding: 1rem 0.5rem;
    border-bottom: 1px solid $cockpit-content-border-bottom-color;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.table--folio-results {
  .folio-result-count {
    display: flex;
    flex-direction: row;
    align-items: center;
    .icon-alert {
      margin-left: 1rem;
      margin-right: 0.5rem;
      color: $tm-yellow-dark;
    }
  }
}

.table-rhv {
  width: 100%;
  border-collapse: separate;
  border-spacing: 4px;

  th {
    font-weight: bold;
  }

  td.table-rhv__cell {
    position: relative;
    border: 2px $cockpit-content-border-bottom-color solid;
    padding: 4px;
  }

  &__user-count {
    position: absolute;
    bottom: 0;
    right: 0;
    border-top: 1px $cockpit-content-border-bottom-color solid;
    border-left: 1px $cockpit-content-border-bottom-color solid;
    margin-bottom: 0;
    font-weight: bold;
    text-align: center;
    line-height: 30px;
    width: 30px;
    height: 30px;
  }
}
