.cockpit-results {
  display: inline-block;

  &--actions,
  &--toggles {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0.25rem;
    .button-archive {
      min-width: 6.5rem;
    }
  }

  &--toggles {
    justify-content: flex-end;
  }

  &--hover {
    tr:hover:not(.cockpit-results--no-hover) {
      td:not(.cockpit-results--no-hover) {
        background-color: $cockpit-table-background-color;
      }
    }
  }

  &--heading-groups th {
    border-bottom: 0 none !important;
    padding-bottom: 0 !important;
    &.cockpit-results--heading-group span {
      display: block;
      border-bottom: 2px solid $cockpit-content-border-bottom-color !important;
    }
  }

  &--name {
    background-color: $cockpit-table-background-color;
  }

  &--no-border td {
    border: 0;
  }

  &__score-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__multiple-scores {
    display: flex;
    flex-direction: column;
  }

  &__score {
    display: inline-block;
    padding: .4rem;
  }
}

.education-level-selector,
.advice-selector {
  display: inline-flex;
  flex-wrap: nowrap;
  &--centered {
    padding-left: 2rem;
  }
  .advice-selector-reset,
  .education-level-selector-reset {
    width: 2rem;
  }
}
