.notification {
  position: relative;
  align-content: flex-start;
  display: flex;
}

.notification__label {
  flex-shrink: 0;
  text-align: center;
  min-width: 2.75rem;
  margin-right: 0.5rem;
  [class^="icon-"], > [class*=" icon-"] {
    font-size: 1.25em;
  }
}

.notification__text {
  *:first-child { margin-top: 0; }
  *:last-child { margin-bottom: 0; }
}

.notification--warning { color: $warning-color; font-weight: bold; }
.notification--success { color: $success-color; }
.notification--remark-tip { color: $remark-tip-color; }
.notification--remark-hint { color: $remark-hint-color; }
.notification--remark-attention { color: $remark-attention-color; }

.notification--centered { justify-content: center }

// Change this to generic notification; however this is part of legacy templates TODO
.exercise-too-many-options {
  display: none;
  .form-state-too-many-options & { display: block; }

  position: relative;
  padding-left: 3.25rem;
  margin-bottom: .5rem !important;

  &.text-muted { padding-left: 1.5rem; }

  &:before {
    font-family: $pika;
    content: $pika-lock;
    color: $tm-neutral-heavy;
    font-size: 1.25em;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.remark-label {
  color: $white;
  text-transform: uppercase;
  font-size: 0.75em;
  font-weight: $weight-bold;
  padding: 0 0.25em 0.1em 0.25em;
  border-radius: 2px;
}

.remark-label--tip { background-color: $remark-tip-color; }
.remark-label--hint { background-color: $remark-hint-color; }
.remark-label--attention { background-color: $remark-attention-color; }
