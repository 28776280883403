.digital-tests {
  margin-bottom: 3rem;
}

.test-list {
  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid $cockpit-header-border-color;
    padding-bottom: 1rem;
  }

  &__button {
    margin: 0.1rem 0;
  }

  &__actions {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;
  }
}

