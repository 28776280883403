/**
  PRINT STYLES FOR MODAL CONTENT
 */
@page {
  size: auto; /* auto is the initial value */
  margin: 15mm;  /* this affects the margin in the printer settings */
}

.printable-content {
  &__logo {
    height: 6rem;
    vertical-align: top;
    margin-right: 2rem;
  }
  h1 {
    line-height: 1;
  }
  &__logo-color {
    color: #009cbc;
  }
  &__header {
    display: flex;
    flex-direction: row;
  }
}

@media print {
  html {
    background-color: #FFFFFF;
    margin: 0;  /* this affects the margin on the html before sending to printer */
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    page-break-after: avoid; // Prevent headings from being printed at the bottom of the page
  }

  h1 {
    font-size: 1.5rem;
  }
  article {
    page-break-before: always; // Always start new articles on a new page
  }

  section,
  img {
    page-break-inside: avoid; // Prevent images from being split up
  }

  .printable-content {
    &__logo {
      height: 3rem;
      vertical-align: top;
      margin-right: 0.5rem;
    }
    .section .h4 {
      font-size: 1rem;
    }
  }

  .sf-toolbar {
    display: none !important;
  }
}
