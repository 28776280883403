$item-dimensions: 4.5rem;
$item-dimensions-mobile: 3.5rem;
$item-border-width: .25em;
$item-border-radius: 1.25em;
$item-font-size: 1.25em;
$mobile-font-size: 12px;

.lesson-navigation-wrapper {
  background-color: $cockpit-content-navigation-bg-color;
  line-height: 0; // remove some spacing at the top
}

.lesson-navigation-wrapper__inner {
  @include until($tablet) {
    display: flex;
    flex-direction: column;
  }
}

.lesson-navigation__inner {
  line-height: 1.5; // restore/negate previous line-height of 0
}

.lesson-navigation__blocks-wrapper {
  display: inline-flex;
  flex-direction: column;
  width: auto;
}

.lesson-navigation__weeks-wrapper {
  display: flex;
}

.lesson-navigation__week-component,
.lesson-navigation__block-component {
  align-items: center;
  display: inline-flex;
  margin: 0;
  max-height: none;
  opacity: 1;
  position: relative;
  width: 100%; // first set this for IE11
  width: fit-content; // overwrite for all other browsers that support 'fit-content'

  @media only screen and (max-width: 530px) {
    justify-content: space-between;
    width: 100%;
  }

  &::before {
    background-color: $white;
    content: '';
    height: $item-border-width;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 0;
  }

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.lesson-navigation__block-component {
  width: 100%;
}

.lesson-navigation__item {
  background-color: $cockpit-content-navigation-bg-color;
  border: $item-border-width solid $white;
  border-radius: $item-border-radius;

  &--inversed {
    background-color: $white;
    border: $item-border-width solid $cockpit-content-navigation-bg-color;
  }

  font-size: 1rem;
  max-height: $item-dimensions;
  max-width: $item-dimensions;
  overflow: hidden;
  width: $item-dimensions;
  height: $item-dimensions;
  z-index: 1;

  @media only screen and (max-width: 530px) {
    font-size: $mobile-font-size;
    max-height: $item-dimensions-mobile;
    max-width: $item-dimensions-mobile;

    &--week-overview {
      width: 2 * $item-dimensions-mobile;
      max-width: 2 * $item-dimensions-mobile;
    }
  }

  &--small {
    font-size: $mobile-font-size;
    max-height: $item-dimensions-mobile;
    max-width: $item-dimensions-mobile;
    width: $item-dimensions-mobile;
    height: $item-dimensions-mobile;
  }

  &--week-overview {
    width: 3 * $item-dimensions;
    max-width: 3 * $item-dimensions;
  }

  &--block {
    width: 100%;
    max-width: 100%;
  }

  &--active {
    border-color: $cockpit-content-navigation-active-color;
  }

  &--completed {
    background-color: $white;
  }

  &:not(:last-child) {
    margin-right: $item-border-width * 2;
  }

  &.lesson-navigation__item--type {
    &-squared {
      border-radius: 0;
    }

    &-rounded-left {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &-rounded-right {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &-link,
  &-no-link {
    color: $body-color;
    font-size: $item-font-size;
    font-weight: 400;

    align-items: center;
    justify-content: center;
    text-align: center;

    display: flex;
    width: 100%;
    height: 100%;

    &-week,
    &-block {
      background-color: $white;
    }

    .lesson-navigation__item--active & {
      background: $cockpit-content-navigation-active-color;
      color: #fff;
    }
  }

  &-link:hover {
    color: $cockpit-content-navigation-active-color;
  }
}

.lesson-navigation__inner--collapsed {
  .lesson-navigation__week-component {
    margin-bottom: 0;
    &:not(.lesson-navigation__week-component--current-week) {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: all .3s ease-out;
    }
  }

  .lesson-navigation__block-component {
    margin-bottom: 0;
    max-width: 20rem;
    &:not(.lesson-navigation__block-component--current-block) {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: all .3s ease-out;
    }
  }

  .learn-lines {
    margin-bottom: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all .3s ease-out;
  }
  
  .tabs--cockpit {
    border-bottom: 0;
    height: 0;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    opacity: 0;
    overflow: hidden;
  }
}

.lesson-navigation__inner--expanded {
  .lesson-navigation__week-component,
  .lesson-navigation__block-component {
    transition: all .3s ease-in;

    &:nth-child(1) {
      transition-delay: 0ms;
    }

    &:nth-child(2) {
      transition-delay: 75ms;
    }

    &:nth-child(3) {
      transition-delay: 150ms;
    }

    &:nth-child(4) {
      transition-delay: 225ms;
    }

    &:nth-child(5) {
      transition-delay: 300ms;
    }

    &:nth-child(6) {
      transition-delay: 375ms;
    }
  }
}

.tab-pages--cockpit-content-lesson-navigation {
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 530px) {
    width: 100%;
  }
}

.lesson-navigation__inner--collapsed .tab-pages--cockpit-content-lesson-navigation {
  margin-top: 0 !important;
}
