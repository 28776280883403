.cockpit-content {
	flex: 1 0 auto;
	margin-bottom: 5rem; // add some space to account for logo inside footer (with negative top positioning)

	&__inner {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

  .lesson-detail-view__block-title {
    color: $cockpit-medium-grey;
    font-weight: $weight-normal;
    text-indent: 3px;
  }
}
