.category__title {
  font-size: 1.25rem;
}

.category__subtitle {
  color: $cockpit-light-grey;
  font-size: 1rem;
}

.lesson__topic {
  margin-bottom: 0;
  min-width: 17.5rem;
  position: relative;

  .topic__meta {
    font-size: 0.75rem;
    text-transform: uppercase;
  }

  .topic__title {
    font-family: $family-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.1rem;
    margin-bottom: 0;
  }

  .topic__content {
    border-radius: .3125rem;
    display: flex;
    padding-bottom: 66.67%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .topic__content-inner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    color: $white;
    display: flex;
    flex-direction: column;
    left: 0;
    justify-content: space-between;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    height: 70.59%;
    transition: opacity .3s ease-in-out;

    &,
    &::before {
      pointer-events: none;
    }

    &::before {
      content: '';
      display: flex;
      height: 100%;
      left: 0;
      opacity: 1;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }

    &--with-shadow-overlay::before {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
    }

    &--no-bg::before {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%), $cockpit-blue;
    }
  }

  .topic__content:hover .topic__content-inner {
    opacity: .8;
  }

  .topic__header {
    position: absolute;
    bottom: 0;
    height: 29.41%;
    width: 100%;
    z-index: 2;
    color: $base-text-color;
  }

  .topic__header--bordered {
    border-top: 1px solid darken($white, 10%);
  }

  .card--bordered {
    .topic__header {
      height: 100%;
      padding: 1.5rem;
      color: white;
    }
    .topic__content-inner {
      height: 100%;
    }
  }
}


