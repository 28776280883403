.lesson__goals {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
  align-items: flex-start;

  @include until($tablet) {
    margin-top: 1rem;
  }

  .section {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.lesson__goals-title {
  @media screen and (min-width: 601px) {
    font-size: 22px;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 600px) {
    font-size: 1rem;
	  margin-bottom: .25rem;
  }
}

.lesson__goals .list,
.lesson__goals-list {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0;
}

.lesson__goals .list li,
.lesson__goal-item {
	align-items: flex-start;
	display: flex;
	line-height: 1.35;
  margin-left: 0;

	&:not(:last-child) {
		margin-bottom: .5rem;
	}

  &::before,
  .icon-check {
    display: flex;
    margin-right: .5rem;
  }

  .icon-check::before,
  &::before {
    line-height: 1.5;
  }

  p {
    margin: 0;
  }
}

.lesson__goals .list li {
  margin: 0;
  padding: 0;

  &::before {
    content: $pika-check;
    font-family: $pika;
  }
}
