@mixin readspeaker-wrapper($top: 4rem, $height: 0, $margin-right: -.25rem, $position: sticky) {
  height: $height;
  margin-right: $margin-right;
  position: $position;
  text-align: right;
  top: $top;
  z-index: 9;

  .labeled-title + & {
    margin-bottom: 4rem;
    margin-top: -4.75rem;
    padding-top: .75rem;
  }
}

form.question .question__primary-container {
  position: static !important;
}

.readspeaker-wrapper {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  z-index: 9;
}

.theory-readspeaker-wrapper {
  @include readspeaker-wrapper();
}

.terms-readspeaker-wrapper {
  @include readspeaker-wrapper(9rem);
}

.answer-feedback-readspeaker-wrapper {
  @include readspeaker-wrapper(4rem, auto, 0, absolute);
  order: 2;
  right: 1rem;
  top: 1rem;

  + div {
    padding-top: 2.75rem;
  }
}

.question-page__theory-pane {
  .resource-readspeaker-wrapper {
    right: 3.5rem;
  }
}

.rsbtn.rsbtn--edition {
  position: relative;
  display: inline-flex;
  border-radius: $readspeaker-border-radius;
  background-color: $readspeaker-background-color;
  color: $body-color;
  height: $readspeaker-button-size;
  border: $readspeaker-border;

  .rsbtn_btnlabel,
  .rsbtn_label,
  .rsbtn_powered,
  .rsbtn_focusforward,
  .rsbtn_right,
  .rsbtn_volume,
  .rsbtn_volume_container,
  .rsbtn_progress_handle,
  .rsbtn_exp,
  .rsbtn_toolpanel,
  .rsbtn_rewind,
  .rsbtn_forward,
  .rsbtn_stop,
  .rsbtn_speed,
  .rsbtn_speed_container
  {
    display: none;
  }

  &.rsexpanded {
    .rsbtn_exp,
    .rsbtn_toolpanel,
    .rsbtn_exp_inner {
      display: flex;
    }
  }

  //reset styling on readspeaker buttons
  .rsbtn_player_item {
    border: none;
    background: transparent;
    padding: 0;
  }

  .rsbtn_play {
    color: currentColor;
    //icon holder in play button
    .rsbtn_left {
      display: flex;
    }
  }
  &.rsexpanded .rsbtn_play {
    pointer-events: none;
  }

  .rsbtn_play, .rsbtn_pause, .rsbtn_closer {
    width: $readspeaker-button-size;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;

    .rsicn, .icon-high-volume {
      vertical-align: middle;
      line-height: 1;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      cursor: pointer;
    }
  }
  .rsbtn_play, .rsbtn_closer {
    &:hover {
      background-color: $readspeaker-background-hover-color;
    }
  }
  .rsbtn_play {
    border-radius: $readspeaker-border-radius;
  }
  .rsbtn_closer {
    border-radius: 0 $readspeaker-border-radius $readspeaker-border-radius 0;
  }
  .rsbtn_pause {
    color: $white;
    background-color: $primary-button-color;
    margin: $readspeaker-pause-play-margin;
  }

  .rsicn-cross { @include icon-before($icon: $pika-delete, $size: $readspeaker-icon-size); }
  .rsicn-pause { @include icon-before($icon: $pika-pause, $size: $readspeaker-icon-size); }
  &.rspaused .rsicn-pause { @include icon-before($icon: $pika-play, $size: $readspeaker-icon-size); }
  &.rsstopped .rsicn-pause { @include icon-before($icon: $pika-replay, $size: $readspeaker-icon-size); }
}

.rs_textselection {
  .sync_sent,
  .sync_sent_highlighted {
    border-bottom: 1px dashed $body-color !important;
  }
}
