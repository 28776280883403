.centered-container {
  display: flex;
  position: fixed;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

.centered-logo {
  width: 10rem;
  height: 10rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
