.cockpit-footer {
	background-color: $cockpit-footer-bg-color;

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&__logo {
		max-width: 16rem;
		position: absolute;
		right: 0;
		top: 0;
		transform: translateY(-40%);

		@include until($desktop) {
			max-width: 16rem;
			right: baseline(1.25);
		}

		@include until($tablet) {
			max-width: 10rem;
		}
	}

	&__navigation {
		display: flex;
		margin: 0;

		@include until($tablet) {
			flex-direction: column;
		}

		@include from($tablet) {
			align-items: center;
		}

		&-item {
			&:not(:last-child) {
				margin-right: 1rem;
			}
		}

		&-logo {
			display: block;
			margin: 0;

			@include until($tablet) {
				margin-bottom: 1rem;
			}

			&-link {
				display: block;
			}
		}
	}

	&__navigation-link,
	&__credit-link {
		color: $cockpit-footer-navigation-link-color !important;
		display: inline-block;
		opacity: 1;
		transition: opacity .3s;

		&:hover {
			opacity: .7;
		}
	}
}
