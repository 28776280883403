$toggle-button-background-color-off: $cockpit-light-grey;
$toggle-button-background-color: $tm-blue;
$toggle-button-color: $white;

.toggle-button {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  width: 3.5rem;
  height: 1.7rem;
  padding: 0.15rem;
  color: $toggle-button-color;
  background-color: $toggle-button-background-color-off;
  border-radius: 1rem;
  font-size: 0.8rem;
  line-height: 0.8rem;
  input {
    display: none;
  }
  .toggle-button__switch {
    display: block;
    height: 1.3rem;
    width: 1.3rem;
    background-color: $toggle-button-color;
    border-radius: 1rem;
  }
  &__label-off,
  &__label-on {
    text-align: center;
    font-weight: bold;
    width: 1.9rem;
  }
}

.toggle-button--disabled {
  pointer-events: none;
}

.toggle-button--disabled {
  color: $cockpit-light-grey;
  background-color: $grey-lighter;
  .toggle-button__switch {
    background-color: $cockpit-light-grey;
  }
}

.toggle-button--on {
  background-color: $toggle-button-background-color;
  &.toggle-button--disabled {
    color: $disabled-button-text-color;
    background-color: $disabled-button-color;
    .toggle-button__switch {
      background-color: $disabled-button-text-color;
    }
  }
}
