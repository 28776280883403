/* Variables */
$cockpit-light-grey: #c9c5bc;
$cockpit-medium-grey: #8d887e;
$cockpit-dark-grey: #333333;
$cockpit-blue: #0190d4;
$cockpit-light-blue: #15b3fe;

$cockpit-link-color: $cockpit-blue;

$cockpit-header-bg-color: #ffffff;
$cockpit-header-feedback-bubble-color: $cockpit-blue;
$cockpit-header-feedback-bubble-color-hover: $cockpit-light-blue;
$cockpit-header-border-color: $cockpit-light-grey;

$cockpit-content-navigation-bg-color: #f1f0eb;
$cockpit-content-navigation-active-color: $cockpit-light-blue;

$cockpit-content-border-bottom-color: #f1f0eb;

$cockpit-footer-bg-color: $cockpit-content-navigation-bg-color;
$cockpit-footer-navigation-link-color: #8d887e;

$cockpit-tabs-active-tab-color: $cockpit-blue;
$cockpit-tabs-inactive-tab-color: $cockpit-light-grey;
$cockpit-tabs-hover-tab-color: #8d887e;

$cockpit-toggle-label-color: $cockpit-dark-grey;
$cockpit-toggle-icon-color: $cockpit-light-grey;

$cockpit-readmore-color: $cockpit-link-color;
$cockpit-readmore-icon-color: $cockpit-dark-grey;

$anthracite: #343434;
$medium-grey: #CACACA;
$light-grey: #E7E7E7;

$blue-light-pale: #E5EEF5;

$red-bright: #E2004F;
$red-regular: #D1272E;
$red-dark: #A62126;

$green-pale: #96BD0D;
$green-bright: #30B93C;
$green-dark: #4B8C3C;

$border-radius-default: 4px;

$text-color: $anthracite;
$border-color: $light-grey;
$input-border-color: $medium-grey;

$background-panel: $blue-light-pale;

$button-font-size: .75rem;
$button-border-radius: $border-radius-default;
$button-background-color-primary: $red-regular;
$button-background-color-primary-hover: $red-dark;

$form-element-border-color: $medium-grey;
$form-element-text-color: $heading-light-color;
$form-element-font-size: 1rem;
$form-element-font-family: $family-sans-serif;
$font-family-header: $family-serif;

@import "./cockpit/header";
@import "./cockpit/navigation";
@import "./cockpit/cards";
@import "./cockpit/content";
@import "./cockpit/content-tabs";
@import "./cockpit/content-cards";
@import "./cockpit/dropdowns";
@import "./cockpit/lesson-navigation";
@import "./cockpit/lesson-navigation-toggle";
@import "./cockpit/learn-lines";
@import "./cockpit/lesson-details";
@import "./cockpit/footer";
@import "./cockpit/progress-bar";
@import "./cockpit/result-entry-grid";
@import "./cockpit/summon-list";
@import "./cockpit/form-modal";
@import "./cockpit/stopwatch";
@import "./cockpit/test";
@import "./cockpit/date-time-input";
@import "./cockpit/table";
@import "./cockpit/results";
@import "./cockpit/learning-goals";

@import "../score";

.has-loading-bar {
  position: relative;
  @include loading-bar($height: 5px, $color: $cockpit-blue);
}

.cockpit-page {
  &.has-sticky-footer {
    &,
    #cockpit-app {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
    }
  }
}
