.lesson__status-toggle {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 2rem;
  position: relative;
  width: $lesson-status-toggle-container-width;

  @include from($tablet) {
    height: $lesson-status-toggle-container-height;
  }

  .lesson__status-icon {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: color .5s ease-in-out;

    &,
    &::before {
      display: flex;
      font-size: 20px;
      pointer-events: none;
    }
  }

  .lesson-status-toggle__input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 100%;
    opacity: 0;
    position: absolute;

    ~ .lesson__status-background {
      border: 2px solid $lesson-status-toggle-color-unchecked;
      border-radius: 100%;
      cursor: pointer;
      height: $lesson-status-toggle-dimension;
      position: relative;
      transition: background .5s ease-in-out;
      width: $lesson-status-toggle-dimension;
    }

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    &:checked {
      ~ .lesson__status-background {
        background: $lesson-status-toggle-color-checked;
        border-color: $lesson-status-toggle-color-checked;
      }

      ~ .lesson__status-icon {
        color: $white;
      }
    }

    &:not(:checked) {
      ~ .lesson__status-background {
        background: $white;
      }

      &:hover {
        ~ .lesson__status-background {
          border-width: 3px;
        }
      }

      ~ .lesson__status-icon {
        color: $lesson-status-toggle-color-unchecked;
      }
    }
  }
}
