.stopwatch {
  width: 41px;
  height: 48px;
  background-image: url('../images/cockpit/stopwatch.svg');
  background-size: contain;
  background-repeat: no-repeat;
  color: $cockpit-blue;
  padding-top: 0.8rem;
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
}
