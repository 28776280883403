$lesson-status-toggle-dimension: 3rem;
$lesson-status-toggle-container-height: 4rem;
$lesson-status-toggle-container-width: 3rem;
$lesson-status-toggle-color-checked: $tm-good-dark;
$lesson-status-toggle-color-unchecked: $cockpit-light-grey;

$lesson-category-subtitle-color: $cockpit-medium-grey;
$lesson-goals-check-color: $cockpit-blue;

@import './lesson-details/lesson-categories';
@import './lesson-details/lesson-goals';
@import './lesson-details/lesson-modal-content';
@import './lesson-details/lesson-modal-content-print';
@import './lesson-details/lesson-status';
@import './lesson-details/lesson-topics';

.lesson__header {
  border-bottom: 1px solid $cockpit-header-border-color;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding-bottom: 2rem;

  @include until($tablet) {
    flex-direction: column;
  }
}

.lesson__title-wrapper {
  display: flex;

  @include from($tablet) {
    margin-right: 4rem;
  }
}

.lesson__title {
  font-size: 60px;
  font-weight: 400;
  margin-top: -0.5rem !important;

  @include until($tablet) {
    font-size: 3rem;
  }

  @media screen and (max-width: 600px) {
    font-size: 2rem;
  }
}
