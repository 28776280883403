.shepherd-modal-overlay-container {
  fill-rule: evenodd;
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all .3s ease-out, height 0ms .3s, opacity .3s 0ms;
  width: 100vw;
  z-index: 10002;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  height: 100vh;
  opacity: .5;
  transition: all .3s ease-out, height 0s 0s, opacity .3s 0s
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible path {
  pointer-events: all
}

.shepherd-element .shepherd-arrow {
  border: 16px solid transparent;
  content: "";
  display: block;
  height: 16px;
  pointer-events: none;
  position: absolute;
  width: 16px;
  z-index: 10004;
}

.shepherd-element.shepherd-element-attached-bottom .shepherd-arrow {
  bottom: 0;
  border-top-color: $white;
  left: 50%;
  transform: translate(-50%, 100%)
}

.shepherd-element.shepherd-element-attached-top .shepherd-arrow {
  border-bottom-color: $white;
  left: 50%;
  top: 0;
  transform: translate(-50%, -100%)
}

.shepherd-element.shepherd-element-attached-left .shepherd-arrow {
  border-right-color: $white;
  left: 0;
  top: 50%;
  transform: translate(-100%, -50%)
}

.shepherd-element.shepherd-element-attached-right .shepherd-arrow {
  border-left-color: $white;
  right: 0;
  top: 50%;
  transform: translate(100%, -50%)
}

.shepherd-target-click-disabled.shepherd-enabled.shepherd-target, .shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
  pointer-events: none
}

.shepherd-header {
  .shepherd-title {
    font-family: $family-serif;
    font-weight: bold;
    font-size: 1.5rem;
  }

  .shepherd-cancel-icon {
    @include close-button(2.5rem, 2px, $tm-neutral-extrablack, $tm-neutral-extrablack, 50%);

    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    border: none;
    color: transparent;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      background-color: $tm-neutral-pale;
      cursor: pointer;
    }
  }
}

.shepherd-element {
  z-index: 10004;
  padding: $spacer*3;
  background: $white;
  width: 400px;
  outline: none;
  .cockpit-page & {
    width: 500px;
  }
  &--is-float,
  &--is-wide {
    width: 600px;
    @include mobile() {
      width: 350px;
    }
  }
}
.shepherd-footer {
  display: flex;
  justify-content: space-between;
  .cockpit-page & {
    .tour__button--previous,
    .tour__button--next {
      background-color: $cockpit-blue;
      &:hover {
        background-color: $cockpit-light-blue;
      }
    }
  }
}


.tour__seen-all-checkbox {
  margin-right: $spacer;
}
.tour__seen-all-checkbox,
.tour__seen-all-label {
  cursor: pointer;
}
.tour__seen-all-label {
  font-size: 0.8rem;
  font-style: italic;
}
.tour__shortcut-button {
  &:before {
    margin-left: -2px;
    margin-top: -2px;
  }
  color: $primary-button-color;
  position: fixed;
  z-index: 10003;
  width: 20px;
  height: 20px;
  font-size: 24px;
  border-radius: 12px;
  background-color: $white;
  cursor: pointer;
  pointer-events: auto !important;
  @include dropshadow();
  &:hover {
    color: darken($primary-button-color, 7.5%);
  }
  .cockpit-page & {
    color: $cockpit-blue;
    &:hover {
      color: $cockpit-light-blue;
    }
  }
}

%tour-button-icon {
  display: inline-block;
  font-family: $icon;
  font-size: 1.3em;
  vertical-align: middle;
  speak: none;
  line-height: 1;
}
.tour__button--next {
  &::after {
    @extend %tour-button-icon;
    margin-left: 0.2em;
    margin-right: -0.3em;
    content: $icon-chevron-right;
  }
}
.tour__button--previous {
  &::before {
    @extend %tour-button-icon;
    margin-right: 0.2em;
    margin-left: -0.3em;
    content: $icon-chevron-left;
  }
}
.tour__button--video {
  &::before {
    @extend %tour-button-icon;
    font-family: $pika;
    content: $pika-play-video;
    margin-right: $spacer;
  }
}

