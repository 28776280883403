$result-entry-invalid-border: #d00;
$result-entry-invalid-background: #fdd;
$result-entry-grey-dark: #32383c;
$result-entry-grey: #767676;
$result-entry-grey-light: #efefef;

.result-entry-grid__row {
  &--invalid {
    background-color: $result-entry-invalid-background;
  }
}

.show--msg {
  display: block !important;
}

.result-entry-grid__input,
.result-entry-grid__no-input {
  padding: 0.125rem 0.125rem 0.125rem 0.625rem;
  margin: 0 10px 0 0;
  border-width: 0.0625rem;
  border-radius: 5px;
  height: 2rem;
  width: 3rem;
  box-shadow: none;

  &:focus {
    outline-style: solid;
  }

  &--invalid {
    border: 1px $result-entry-invalid-border solid;
    background-color: $result-entry-invalid-background;
  }
}

.result-entry-grid__no-input {
  border: 1px solid rgba($result-entry-grey, 0.3);
  color: rgba($result-entry-grey, 0.6);
  background-color: rgba($result-entry-grey-light, 0.3);
  padding: 0.125rem;
  text-align: center;
}

.result-entry-grid__no-input-message {
  height: 100%;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.result-grid__tabs {
  &--loading {
    pointer-events: none;
    opacity: .5;
  }

  > div {
    display: flex;
  }

  .tabs {
    flex: 0 0 13.75rem;
    background-color: #fff;
  }

  .tabs__link {
    padding: 0.625rem 1rem;
    border: none;
  }

  .tabs__link--active {
    background-color: $result-entry-grey-light;
    color: $result-entry-grey-dark;
    border: none;
  }

  .tabs__link--with-icon {
    .tabs__link-icon {
      .icon-check {
        color: $exercise-correct-dark;
      }
      .icon-alert {
        color: $tm-yellow-dark;
      }
    }
  }

  .tab-pages {
    width: 100%;
    background-color: $result-entry-grey-light;
  }

  .notification {
    padding: 0.625rem 0 0 0;
    font-weight: bold;
    display: none;
    font-style: italic;

    &.error {
      color: red;
    }

    &.success {
      color: green;
    }
  }
}

.result-grid__question,
.result-grid__question-header {
  display: flex;
  justify-content: start;
  align-items: start;
  margin-bottom: 0.5rem;
}

.result-grid__question-score {
  display: flex;
  justify-content: start;
  align-items: center;
}

.result-grid__index,
.result-grid__question-index {
  justify-content: center;
}

.result-grid__question-index {
  padding-top: 0.225rem;
}

.result-grid__question-header {
  font-weight: bold;
  margin-bottom: 0.625rem;
}

.result-grid__question .error--msg {
  padding-top: 0.5rem;
  color: red;
  font-style: italic;
  font-weight: bold;
  display: block;
  font-size: 14px;
  margin-top: -4px;
}

.result-grid__questions {
  width: 100%;
  display: grid;
  grid-template-columns: 7rem 9rem auto;
  gap: 0.125rem;
  padding: 0.625rem 1rem;
}

.result-tab__footer {
  position: sticky;
  padding: 1rem 1.875rem;
  bottom: -1.5rem;
  border-top: 1px solid rgba($result-entry-grey-dark, 0.1);
  background-color: $result-entry-grey-light;
}

.result-tab__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .result-tab__submitted-at {
    display: flex;
    justify-content: start;
    align-items: center;
    .result-tab__submitted-at-title {
      font-weight: bold;
      margin-right: 1rem;
    }
  }
  .result-tab__action-buttons {
    .button--clear {
      margin: 0 0.625rem 0 0;
    }
  }
}

.result-entry-words {
  padding-top: 0.1rem;
  .word {
    display: inline-flex;
    cursor: pointer;
    background: $white;
    padding: 0 0.25rem;
    border: 1px solid $black;
    border-radius: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.25rem;

    .word-icon {
      display: inline-block;
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0.25rem;
    }

    &.correct {
      border-color: $exercise-correct-dark;
      fill: $exercise-correct-dark;
    }

    &.incorrect {
      border-color: $exercise-incorrect-dark;
      fill: $exercise-incorrect-dark;
    }

    &.predefined {
      overflow: hidden;
      padding: 0 0.25rem 0 0;
      fill: $white;
      border-color: rgba($result-entry-grey, 0.6);
      color: rgba($result-entry-grey-dark, 0.6);
      background-color: rgba($result-entry-grey-light, 0.3);
      pointer-events: none;
      cursor: auto;
      .word-icon-container {
        padding: 0 0 0 0.125rem;
        background-color: rgba($result-entry-grey, 0.6);
        margin-right: 0.25rem;
        .word-icon {
          margin-right: 0.125rem;
        }
      }
    }
  }
}
