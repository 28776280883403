.learn-lines {
  width: 100%;
}

.learn-lines__header {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 3rem;

  .dropdown:first-child {
    margin-bottom: 1rem;
  }
}

.learn-lines__block-item {
  align-items: center;
  display: flex;
  height: 5rem;
  margin-bottom: 3rem;
}

.learn-lines__blocks-grid {
  display: flex;
  flex-flow: row wrap;
}

.learn-lines__block-wrapper {
  left: 0;
  margin-right: 5%;
  opacity: 1;
  position: relative;
  transition: all .5s ease-in-out .5s;

  .is-hidden & {
    left: 4rem;
    opacity: 0;
  }
}

.learn-lines__block-title {
  display: block;
  margin-bottom: 1.5rem;
  margin-left: .25rem;
}

.learn-lines__lesson-item {
  border-width: 0;
  border-radius: 1.5rem;
  box-shadow: inset 0 0 0 .25rem $white, 0 0 0 .25rem transparent;
  box-sizing: content-box;
  flex-shrink: 0;
  max-height: 5rem;
  max-width: 5rem;
  transform: scale(1);
  transition: transform .3s ease-in-out;
  width: 19vw;

  @include until($tablet) {
    border-radius: 1.25rem;
    max-height: 4rem;
    max-width: 4rem;
  }

  &:hover {
    transform: scale(1.05);
  }

  &:not(:first-child) {
    margin-left: -.75rem;
  }

  &:not(:last-child) {
    margin-right: 0;
  }

  &.lesson-navigation__item--active + .lesson-navigation__item--active,
  &.lesson-navigation__item--completed + .learn-lines__lesson-item:not(.lesson-navigation__item--active) {
    box-shadow: inset 0 0 0 .25rem $white, 0 0 0 .25rem $cockpit-content-border-bottom-color;
  }
}
