.main-navigation--cockpit {
  border-bottom: 1px solid $cockpit-light-grey;
}

.main-navigation__item {
  border-bottom: 1px solid transparent;
  color: $cockpit-medium-grey;
  display: inline-flex;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: -1px;
  padding-bottom: .5rem;

  &:not(:last-child) {
    margin-right: 4rem;
  }

  &--active {
    border-color: $cockpit-blue;
    color: $cockpit-blue;
    font-weight: bold;
  }
}
