.vc-container {
  border-radius: 0 !important;
  .vc-text {
    font-size: 1rem;
  }
  .vc-btn-grid {
    border-radius: $button-border-radius;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding-bottom: 1rem;
    .vc-btn {
      font-family: $family-sans-serif;
      font-weight: $weight-normal;
      text-rendering: optimizeLegibility;
      color: $body-color;
      cursor: pointer;
      line-height: 1rem;
      font-size: 1rem;
      border-radius: $button-border-radius;
      border: none;
      white-space: nowrap;
      padding: $button-padding;
      transition: background-color 300ms;
      outline: none;
      min-height: 1rem;

      @include button-color(
        $background-color: $cockpit-blue,
        $text-color: $white,
        $hover-background-color: darken($cockpit-blue, 7.5%)
      );

      &.left {
        margin-right: 0;
        @include button-color(
          $background-color: #F0F0F0,
          $text-color: $base-text-color,
          $hover-background-color: darken(#F0F0F0, 7.5%)
        );
      }
    }
  }
}
