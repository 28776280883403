$form-modal-spacer: 1.5rem;

.form-modal {
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  z-index: 100;
}

.form-modal__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($black, 0.5);
  z-index: 101;
}

.form-modal__container {
  margin: 2rem auto;
  padding: $form-modal-spacer;
  z-index: 200;
  background-color: $white;
  position: relative;
  width: 600px;
  max-width: 90%;
  overflow-y: auto;

  .form-modal.is-loading & {
    @include loading-bar($height: 5px, $color: $cockpit-blue);
  }

  &--wide {
    width: 800px;
  }

  &--wider {
    width: 1000px;
  }

  &--no-bottom-padding {
    padding-bottom: 0;
  }
}

.form-modal__body,
.form-modal__actions,
.form-modal__errors,
.form-modal__select_date {
  padding: 0;
  margin: 1.5rem 0;
}

.form-modal__select_date {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 2rem;
}

.form-modal__actions {
  text-align: center;
}

.form-modal__title {
  padding: 0 $form-modal-spacer 1rem 0;
  margin: 0;
  border-bottom: 1px solid $cockpit-header-border-color;

  &--full-width {
    margin-left: 0;
    margin-right: 0;
  }
}

.form-modal__errors {
  color: $warning-color;
  font-weight: $weight-bold;
}

.form-modal__close {
  border: none;
  background: transparent;
  outline: 0;
  right: $form-modal-spacer;
  top: $form-modal-spacer;
  @include close-button($size: 20px, $thickness: 2px, $color: $cockpit-footer-navigation-link-color);
  position: absolute;
}

.form-modal__body {
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @include tablet() {
      flex-direction: row;
      align-items: center;
    }
  }

  .form-group__field {
    font-family: $family-sans-serif;
    border-color: $cockpit-header-border-color;
    border-radius: 4px;
  }

  .form-group--readonly { color: $cockpit-footer-navigation-link-color; }

  .form-group__field--readonly {
    outline: 0;
    border-color: transparent;
    color: $cockpit-footer-navigation-link-color;
  }

  .form-group__label {
    min-width: 200px;
    margin-bottom: 0.5rem;
  }

  .form-group__field--nolabel {
    margin-left: 200px;
  }

  .form-group__userselect {
    max-height: 200px;
    overflow: auto;
  }

  .form-group__userselect-all {
    color: $base-text-color;
  }
}
