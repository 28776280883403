.date-time-input {
  display: flex;
  text-align: center;
  cursor: pointer;

  &__date {
    width: 120px;
    text-align: center;
    cursor: pointer;
  }

  &__time {
    width: 70px;
    text-align: center;
  }
}

.vdp-datepicker__calendar.date-time-input__calendar {
  .cell {
    &:hover {
      border-color: $cockpit-blue !important;
    }
    &.selected {
      font-weight: bold;
      color: $white;
      background: $cockpit-blue;
      &:hover {
        background: darken($cockpit-blue, 7.5%)
      }
    }
  }
}

.date-time-input__calendar--top {
  bottom: 100%;
}
