// TABS NAVIGATION
.tabs--cockpit {
  background-color: transparent;
  border-bottom: none;
  height: auto;
  opacity: 1;
  transition: height .5s ease-in, opacity .5s ease-in .15s;

  .tabs__link {
    position: relative;
    border-color: transparent;
    padding-left: .5rem;
    padding-right: .5rem;
    color: $cockpit-light-grey;

    &:hover {
      background-color: transparent;
      border-color: transparent;

      &:not(.tabs__link--active) {
        color: $cockpit-tabs-hover-tab-color;
      }
    }

    &:after {
      background-color: $cockpit-tabs-active-tab-color;
      bottom: -1px;
      content: '';
      display: none;
      height: 1px;
      left: 0;
      position: absolute;
      transition: all .3s ease-in-out;
      width: 100%;
    }
  }

  .tabs__link--active {
    background-color: transparent;
    color: $cockpit-tabs-active-tab-color;

    &:after {
      display: flex;
    }
  }
}

.tabs--bordered {
  border-bottom: 1px solid #dbdbdb;
  max-width: 0;
  min-width: 472px;
  overflow: visible;
}

.tabs--disabled {
  pointer-events: none;
}

.tab-pages--animated {
  .tab-content {
    display: inline-flex !important;
    height: 100%;
    left: 0;
    margin-left: 0;
    opacity: 1;
    position: relative;
    transition: opacity .5s ease .5s, left .5s ease .5s, position .05s linear;
    z-index: 0;

    @media only screen and (max-width: 530px) {
      width: 100%;
    }

    &.is-hidden {
      left: 2rem;
      opacity: 0;
      overflow: hidden;
      pointer-events: none;
      position: absolute;
      transition: opacity .5s ease, left .5s ease, z-index .5s linear, position .5s linear .7s;
      z-index: -1;
    }
  }
}

.tab-content__inner {
  display: flex;
  flex-direction: column;
}
