$table-color: $text-strong !default;
$table-background-color: $scheme-main !default;

$table-cell-border: 1px solid $border !default;
$table-cell-border-width: 0 0 1px !default;
$table-cell-padding: 0.5em 0.75em !default;
$table-cell-heading-color: $text-strong !default;

$table-head-cell-border-width: 0 0 2px !default;
$table-head-cell-color: $text-strong !default;
$table-foot-cell-border-width: 2px 0 0 !default;
$table-foot-cell-color: $text-strong !default;

$table-head-background-color: transparent !default;
$table-body-background-color: transparent !default;
$table-foot-background-color: transparent !default;

$table-striped-row-even-background-color: $scheme-main-bis !default;

.table {
  @extend %block;

  background-color: $table-background-color;
  color: $table-color;

  th {
    color: $table-cell-heading-color;

    &:not([align]) {
      text-align: left;
    }
  }

  td,
  th {
    border: $table-cell-border;
    border-width: $table-cell-border-width;
    padding: $table-cell-padding;
    vertical-align: top;
    &.no-border-bottom {
      border-bottom-width: 0;
    }
    &.border-bottom {
      border-bottom-width: 1px;
    }
  }

  tr.align-center {
    th, td {
      text-align: center;
    }
  }

  tr.vertical-align-middle {
    th, td {
      vertical-align: middle;
    }
  }

  tr.vertical-align-bottom {
    th, td {
      vertical-align: bottom;
    }
  }

  tr.no-bottom-border {
    th, td {
      border-bottom: 0 none;
    }
  }

  tr, tr.align-center {
    td,
    th {
      &.text-align-left {
        text-align: left;
      }

      &.text-align-right {
        text-align: right;
      }

      &.text-align-center {
        text-align: center;
      }

      &.text-align-justify {
        text-align: justify;
      }
    }
  }

  thead {
    background-color: $table-head-background-color;

    td,
    th {
      border-width: $table-head-cell-border-width;
      color: $table-head-cell-color;
    }
  }

  tfoot {
    background-color: $table-foot-background-color;

    td,
    th {
      border-width: $table-foot-cell-border-width;
      color: $table-foot-cell-color;
    }
  }

  tbody {
    background-color: $table-body-background-color;

    tr {
      &:last-child {
        td,
        th {
          border-bottom-width: 0;
        }
      }
    }
  }

  &.table--bordered {
    td,
    th {
      border-width: 1px;
    }

    tr:last-child {
      td,
      th {
        border-bottom-width: 1px
      }
    }
  }

  &.table--condensed {
    td,
    th {
      padding: 0.25em 0.5em;
    }
  }

  &.table--full-width {
    width: 100%;
  }

  td, th {
    &.horizontal-divider {
      &--left, &--right {
        padding: 0.375rem;
      }
      &--right {
        border-left: 2px dotted $border;
      }
    }
  }
}

.sticky-table {
  th {
    position: relative;
    border: 0;

    /// Simulate a border-bottom with this pseudo element
    /// This is needed because these can be sticky elements
    /// and the borders would be hidden on scroll
    &:not(.no-border-bottom)::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: $border;
    }
  }

  tr:last-child {
    th {
      border-bottom: 0;
      position: relative;

      &::before {
        height: 3px;
      }
    }
  }
}

@for $i from 1 through 4 {
  .sticky-table-heads-#{$i} {
    thead {
      tr {
        &:first-child {
          top: 0;
        }

        @for $j from 1 through $i {
          &:nth-child(#{$j}) {
            position: sticky;
            z-index: 2;
            background: $white;
          }
        }
      }
    }
  }

  .sticky-table-columns-#{$i} {
    tr {
      :is(td, th) {
        &:first-child {
          left: 0;
        }

        @for $j from 1 through $i {
          &:nth-child(#{$j}) {
            position: sticky;
            z-index: 1;
            background: $white;
          }

          &:not(.no-border-right):nth-child(#{$i}) {
            /// Simulate a border-right with this pseudo element
            /// This is needed because the last row is sticky
            /// and the border would be hidden on scroll
            &::after {
              content: '';
              position: absolute;
              bottom: 0;
              right: 0;
              width: 3px;
              height: 100%;
              background: $border;
            }
          }
        }
      }
    }
  }
}


.table--no--border{
  th,
  td{
    border-width:0;
  }

  .td--border--top{
    td,
    th{
      //border: $table-cell-border;
      //border-width: $table-cell-border-width;
      border-top: $table-cell-border;
      //border: 1px solid #000;
    }
  }
}
