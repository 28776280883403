.lesson-navigation__toggle {
  color: $cockpit-toggle-label-color;

  &-container {
    @include until($tablet) {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 1rem;
    }

    @include from($tablet) {
      position: absolute;
      right: 1.875rem;
      top: 1.5rem;
      z-index: 1;
    }
  }

  &-icon {
    align-items: center;
    border: 1px solid $cockpit-toggle-icon-color;
    border-radius: 100%;
    color: $cockpit-toggle-icon-color;
    display: inline-flex;
    font-weight: 100;
    height: 1.5rem;
    justify-content: center;
    margin-left: .5rem;
    padding: .5rem;
    transform: rotateZ(0deg);
    transition: transform 0.3s ease-in-out;
    width: 1.5rem;

    &::before {
      display: block;
      transform: translateX(-.5rem);
      width: 0;
    }
  }

  &--expanded {
    .lesson-navigation__toggle-icon {
      transform: rotateZ(-180deg);
    }
  }
}
